import { graphql } from "gatsby";
import React from "react";
import Meta from "../components/common/Meta";
import Layout from "../components/layout/Layout";
// import Page from "../components/layout/Page";
import { StoreContextProvider } from "../hooks/contexts";
import useStoryblok from "../utils/storyblok";

export default function PageIndex({ data }) {
    const story = useStoryblok(data.story);

    const maintenanceWrapperStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      background: '#242f4f',
      zIndex: 1000,
      fontFamily: '"Helvetica Neue", Arial, Helvetica, sans-serif',
    }

    return (
      <StoreContextProvider>
        <Layout showLoader={true} paddingTop={false}>
          <Meta meta={story.content.meta} />
          <div id='maintenence-wrapper' style={maintenanceWrapperStyle}>
            <div style={{ fontFamily: '"Helvetica Neue", Arial, Helvetica, sans-serif' }}>Under maintenance</div>
          </div>
          {/* <Page blok={story.content} /> */}
        </Layout>
      </StoreContextProvider>
    )
}

export const query = graphql`
    {
        story: storyblokEntry(full_slug: { eq: "home" }) {
            name
            content
            full_slug
            uuid
        }
    }
`;
